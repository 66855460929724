import React from "react";
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it

import ScrollAnalytics from '../components/analytics/scrollanalytics';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { dimensions, colors } from '../shared/styles';
import Section from '../components/section';

import DotBackground from '../components/dotbackground';
import Frame from '../components/frame';

import content from '../data/static-content.js';
import team, { partners } from '../data/team';

import backgroundSmallDot from '../images/background-small-dot.png';

import { behaviourTag } from '../shared/analytics';

const MainSection = styled(Section)`
  padding-top : ${dimensions.headerHeight};
  overflow : hidden;
  position : relative;
`;

const TitleFrameContainer = styled.div`
  display : flex;
  margin : 3rem auto 7rem 10rem;

  @media screen and (max-width: 425px) {
    margin : 3rem 5rem 7rem 5rem;
    flex-align : flex-end;
  }
`;

const MainSectionBackground = styled(DotBackground)`
  position : absolute;
  left : 15%;
  width : 60%;
  height : 100%;
`;

const PeopleSection = styled.div`
  position : relative;
  padding : 3rem;
  display : flex;
  flex-direction : row;
  margin : 10rem;
  align-items : flex-start;

  @media screen and (min-width: 426px) {
    &:nth-child(2n+0) {
      flex-direction : row-reverse;
    }

    &:nth-child(2n+1) > div:nth-child(1), &:nth-child(2n+0) > div:nth-child(2){
      margin-right : 8rem;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction : column-reverse;
    align-items : center;
    margin : 2rem;
  }
`;

const PictureContainer = styled.div`
  flex-basis : 38rem;
  flex-shrink : 0;

  @media screen and (max-width: 425px) {
    height : 32rem;
    flex-basis : 32rem;
    flex-shrink : 1;
  }
`;

const ShadowPictureContainer = styled.div`
  width : 31rem;
  height : 31rem;
  position : relative;

  &::before {
    content: "";
    position : absolute;
    width : 100%;
    height : 100%;
    top : 1.1rem;
    left : -1.1rem;
    background-color : ${colors.lightGrey};
  }
`;

const Picture = styled(Img)`
  width : 31rem;
  height : 31rem;
`

const PictureLabelContainer = styled.div`
  width : 22rem;
  height : 8rem;
  position : relative;
  display : flex;
  left : 14rem;
  top : -4rem;

  &::before {
    content: "";
    position : absolute;
    width : 100%;
    height : 100%;
    top : 1.1rem;
    left : -1.1rem;
    border : 1px solid black;
  }
`;

const PictureLabel = styled.div`
  position : relative;
  font-size : 1.8rem;
  background-color : ${colors.blue};
  color : white;
  flex : 1;
  padding : 0 2.1rem;
  display : flex;
  flex-direction : column;
  align-items : flex-start;
  justify-content : space-evenly;
`;

const Name = styled.div`
  font-weight : 800;
`;

const Position = styled.div``;

const DottedShadowFrame = styled.div`
  position : relative;

  &::before {
    content: "";
    position : absolute;
    width : 100%;
    height : 100%;
    max-height : 18rem;
    bottom : -2rem;
    left : -2rem;
    background-image    : url('${backgroundSmallDot}');
    background-repeat   : repeat;
    background-position : bottom left;
  }

  @media screen and (max-width: 425px) {
    &::before {
      left : auto;
      right : -2rem;
    }
  }
`;

const WhiteBackgroundContainer = styled.div`
  background-color : white;
  position : relative;
  padding : 5.5rem;
  font-size : 1.8rem;
`

const Paragraph = styled.p`
  line-height : 3rem;
`;

const DescriptionDecorator = styled.img`
  width : 21.2rem;
  height : 2.2rem;

  @media screen and (max-width: 425px) {
    display : none;
  }
`;


const PartnersSection = styled(Section)`
  padding-top : ${dimensions.headerHeight};
  overflow : hidden;
  position : relative;
  width : 100%;
  padding : 10rem;

  @media screen and (max-width: 425px) {
    padding : 3rem;
  }

`;

const PartnersRow = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : space-around;
  padding-bottom : 10px;

  // @media screen and (max-width: 425px) {
    flex-wrap : wrap;
  // }
`;

const Partner = styled.div`
  position : relative;
  display : inline-flex;
  width : 25.1rem;
  height : 12.5rem;
  border : 1px solid black;
  background-color : white;
  overflow : hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom : 10px;


  @media screen and (max-width: 425px) {
    width : 16rem;
    height : 8rem;
    margin-bottom : 3rem;
  }

  & .description {
    display : none;
  }

  &:hover .description {
    display : flex;
  }

  &:hover .image {
    display : none;
    align-item : center;
  }
`;

const PartnerImage = styled.img`
  width : 100%;
  height : 100%;
  object-fit : contain;
  position : absolute;
  top : 0px;
  left : 0px;
  padding : 0.3rem;

  @media screen and (max-width: 425px) {
    width : 15rem;
    height : 7rem;
  }
`;

const PartnertDescription = styled.div`
  position : absolute;
  display : flex;
  flex : 1;
  flex-direction : row;
  align-items : center;
  justify-content : center;
  font-size : 1.6rem;
  font-weight : 800;
  background-color : ${colors.darkBlue};
  color : white;
  width : 100%;
  height : 100%;
`;



const ReferencePage = () => {
  const picturesData = useStaticQuery(graphql`
    query {
      team : allFile(
        filter: {
          relativeDirectory: { eq: "team" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const pictures = [...picturesData.team.edges].reduce(
    (pictures, next) => {
      pictures[next.node.base] = next.node.childImageSharp.fluid;
      return pictures;
    }, {}
  );

  return (
    <Layout>
      <SEO title="Appunto" />
      <MainSection>
        <MainSectionBackground />
        <TitleFrameContainer>
          <Frame>
            <h1>{content.about.title}</h1>
          </Frame>
        </TitleFrameContainer>
        {
          team.map(
            (person, personIndex) =>
              <PeopleSection key={personIndex}>
                <DottedShadowFrame>
                  <ScrollAnalytics action={`scroll-about-${person.name.replace(' ', '-').toLowerCase()}`}>
                    <WhiteBackgroundContainer>
                      <DescriptionDecorator src={require('../images/about-description-decorator.svg')} />
                      {
                        person.description.map(
                          (paragraph, i) => <Paragraph key={i}>{paragraph}</Paragraph>
                        )
                      }
                    </WhiteBackgroundContainer>
                  </ScrollAnalytics>
                </DottedShadowFrame>
                <PictureContainer>
                  <ShadowPictureContainer>
                    {
                      pictures[person.picture] ? <Picture fluid={pictures[person.picture]} /> : null
                    }
                  </ShadowPictureContainer>
                  <PictureLabelContainer>
                    <PictureLabel>
                      <Name>{person.name}</Name>
                      <Position>{person.position}</Position>
                    </PictureLabel>
                  </PictureLabelContainer>
                </PictureContainer>
              </PeopleSection>
          )
        }
      </MainSection>

      <ScrollAnalytics action="scroll-about-partners">
        <PartnersSection>
          <h2>{content.about.partnersTitle}</h2>
          {
            partners.map(
              (row, rowIndex) =>
              <PartnersRow key={rowIndex}>
                {
                  row.map(
                    (partner, partnerIndex) =>
                    <a href={partner.link} target="_blank" title={partner.link}>
                      <Partner
                        key={partnerIndex}
                        onMouseEnter={() => behaviourTag(`partner-hover-${partner.hash}`)}
                        onClick={() => behaviourTag(`partner-click-${partner.hash}`)}
                      >
                        <PartnerImage src={partner.picture} />
                        <PartnertDescription className='description'>{partner.description}</PartnertDescription>
                      </Partner>
                      </a>
                  )
                }
              </PartnersRow>
            )
          }
        </PartnersSection>
      </ScrollAnalytics>
    </Layout>
  );
}

export default ReferencePage


