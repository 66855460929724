const team = {
  mattia : {
    name : 'Mattia Longhin',
    position : 'Fondateur et CEO',
    description : [
      'Chef de projet, puis CTO et finalement Directeur de Business Unit dans une société spécialisée dans la création d’objet connectés pour les domaines industriel et médical, Mattia a eu la possibilité de se confronter à une large gamme de sujets techniques.',
      'En créant Appunto, Mattia a voulu combiner sa passion pour le développement logiciel avec son expertise dans la gestion de projets complexes. C’est donc autour du rôle de CTO externalisé que l’activité d’Appunto s’est développée.'
    ],
    picture : 'mattia.jpg'
  },
  adrien : {
    name : 'Adrien Gledel',
    position : 'Full Stack',
    description : [
      'Adrien adooooore la programmation ! Tombé là-dedans un peu par hasard après quelques années dans la compta, maintenant il n’arrête plus de coder. S’il ne s’agit pas d’un projet pour Appunto, il y a toujours un projet personnel sur lequel travailler.',
      'AWS, Node et React sont ses bread and butter.'
    ],
    picture : 'adrien.jpg'
  },
  marco : {
    name : 'Marco Silvestroni',
    position : 'Full Stack',
    description : [
      'En direct de la belle région italienne des Marches, Marco est un expert du front et un ninja du back. Véritable couteau suisse de la programmation, si quelque chose peut être codé, Marco peut la coder.',
      'Il a débugué tant des choses, que nous, humains, ne pourrions pas croire…'
    ],
    picture : 'marco.jpg'
  },
};

const partnersDb = {
  tellmemore : {
    hash : "tmm",
    picture : require('../images/partners/tmm3-min.png'),
    description : 'Co-construction',
    link : "https://www.tellmemore.agency"
  }/*,
  neographe : {
    hash : "neographe",
    picture : 'neographe.png',
    description : 'UI design'
  },
  histoiresDigitales : {
    hash : "histoires-digitales",
    picture : 'histoires-digitales.png',
    description : 'Réseaux sociaux'
  }*/,
  pointDuJour : {
    hash : "point-du-jour",
    picture : require('../images/partners/pdj.png'),
    description : 'Financement',
    link : "https://pdj-conseil.fr"
  },
  quadratureDuCercle : {
    hash : "quadrature-du-cercle",
    picture : require('../images/partners/qdc3-min.png'),
    description : 'Datavisualisation',
    link : "https://quadrature-du-cercle.com"
  },
  stormStudio : {
    hash : "stormstudio",
    picture : require('../images/partners/strmstudio-min.png'),
    description : 'UI & UX design',
    link : "https://www.strmstudio.com"
  }
}

export default [
  team.mattia,
  team.adrien,
  team.marco
];

export const partners = [
  [partnersDb.tellmemore, partnersDb.pointDuJour, partnersDb.quadratureDuCercle, partnersDb.stormStudio]
]
